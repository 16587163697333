@font-face {
    font-family: 'HumanBBYDigital';
    src: url('./assets/fonts/HumanBBYDigital-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HumanBBYDigital';
    src: url('./assets/fonts/HumanBBYDigital-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HumanBBYDigital';
    src: url('./assets/fonts/HumanBBYDigital-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

html {
    overscroll-behavior: none;
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
    min-width: auto;
}

body {
    background-color: #fafafa;
    height: 100%;
    min-height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'HumanBBYDigital, sans-serif';
}

.k-popup .k-list .k-item,
.k-list-optionlabel {
    font-family: HumanBBYDigital;
    font-size: 12;
}

.k-popup .k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-hover.k-state-selected {
    color: #1d252c;
    background-color: #f0f2f4;
}

#root {
    height: 100%;
}
